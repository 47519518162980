export const EXPCOLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: 'Código',
    accessor: 'code',
  },
  {
    Header: 'Árbol',
    accessor: 'tree',
  },
  {
    Header: 'Editar',
    accessor: 'edit',
  },
];

export const MINIFICHASCOLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: 'Código',
    accessor: 'code',
  },
  {
    Header: 'Editar',
    accessor: 'edit',
  },
];