import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSessionStorage } from "src/modules/hooks/useSessionStorage";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [role, setRole] = useSessionStorage('role', null)
  const [setupInfo, setSetupInfo] = useState({})
  const [token, setToken] = useSessionStorage("token", null);
 
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ role, setRole, setupInfo, setSetupInfo, token, setToken }}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => useContext(AppContext);

