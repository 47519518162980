import React, { useEffect, useState } from "react";
import "./Login.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useNavigate } from "react-router";
import { API_URL } from "../../config.js";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { User } from "src/lib/api";
const googleIcon = "/googleicon.png";
const Login = () => {
  const { setRole, setToken } = useAppContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const buttonStyle = {
    minHeight: "44px",
    minWidth: "144px",
  };
  useEffect(() => {
    setEmail("");
    setPassword("");
  }, []);

  function handleChange(name, value) {
    if (name === "email") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  }
  const login = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      alert("Por favor, rellene todos los campos");
    }
    const body = JSON.stringify({
      password,
      email,
    });
    const response = await fetch(`${API_URL}/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    const json = await response.json();
    if (!json.token) return alert("Usuario o contraseña incorrectos");

    const bearer = `Bearer ${json.token}`;
    setToken(bearer);
    setRole(await User.get().then((user) => user.role));
    navigate("/expedientes");
  };

  const loginWithGoogle = async (event) => {
    event.preventDefault();
    console.log("login with google");
  };
  return (
    <div>
      <div className="content-container">
        <form className="login-container">
          <p>¿Tienes una cuenta?</p>
          <div className="css-175oi2r">
            <Input
              name="email"
              type="text"
              placeholder="Email"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={email}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={password}
            />
          </div>
          <div className="css-175oi2c" style={{ alignItems: "center" }}>
            <Button
              content="Iniciar Sesión"
              disabled={email && password ? false : true}
              buttonStyle={buttonStyle}
              onClick={login}
            />
            <p>¿Has olvidado tu contraseña?</p>
          </div>
          <div className="css-175oi2r line-container">
            <div className="line" />
            <div className="separator">O</div>
            <div className="line" />
          </div>
          <p>¿Tienes cuenta de Google?</p>
          <Button
            content={
              <img
                src={googleIcon}
                alt="google-icon"
                className="resize_fit_center"
                style={{ gridColumn: 1, gridRow: 1 }}
              />
            }
            disabled={false}
            onClick={loginWithGoogle}
            buttonStyle={buttonStyle}
          />
        </form>
        <br />
        <p>¿Necesitas ayuda para iniciar sesión?</p>
        <br />
      </div>
    </div>
  );
};
export default Login;
