import "./App.css";
import NavBar from "src/modules/components/header/NavBar/NavBar";
import PagesData from "./PagesData";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import React from "react";
import { useAppContext } from 'src/modules/contexts/AppContextProvider'
import RequireAuth from "src/modules/components/header/RequireAuth/RequireAuth";
function App() {
  const { role } = useAppContext()
  function createRouteByRol(pageId) {
    const page = PagesData.find(page => page.id === pageId)
    const roleFound = page.roles.includes(role) ? true : false
    return <Route path={page.path} element={<RequireAuth isLogged={roleFound}>{page.component}</RequireAuth>} />
  }
  
  return (
    <div >
      <BrowserRouter>
        <div className="css-175oi2r page-container" >
          <NavBar />
          <Routes >
            {createRouteByRol('inicio')}
            {createRouteByRol('fichas')}
            {createRouteByRol('reltree')}
            {createRouteByRol('editar')}
            {createRouteByRol('login')}
            {createRouteByRol('cerrar-sesion')}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}



export default App;
