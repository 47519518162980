import React, {  useEffect, useState } from 'react'
import './Inicio.css'
import { EXPCOLUMNS } from 'src/columns'
import SortingTable from 'src/modules/components/porOrganizar/Table/SortingTable'
import { API_URL, API_TOKEN } from "src/config.js";
const Inicio = () => {
  useEffect(() => {
    fetch(`${API_URL}/v1/dossier/list/header.code:asc/650c1b45700e755651e21f2b/0/10000`, {
      method: "GET",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.error) return
        const dossiers = data.map(dossier => {
          const edit = <a href={`/minifichas/${dossier.dossier.header.code}`}>Ver</a>
          return {edit, name:dossier.dossier.header.name, code: dossier.dossier.header.code} 
        })
        setDossiers(dossiers);
      })
      .catch(error => {
        console.log(error)
      });
    }, [])
    const [dossiers, setDossiers] = useState()
    
    return (
      <div>
        { dossiers?.length > 0 ? <SortingTable columnsData={EXPCOLUMNS} tbodyData={dossiers}/> : <h1 style={{display:'flex', textAlign: 'center', flexDirection: 'column'}}>Buscando Expedientes...</h1>}
      </div>
  )
}

export default Inicio