import provs from '../data/provs.json'
import munips from '../data/munips.json'

const print = () => {
  console.log(provs)
  console.log(munips)
}

const getProvincias = () => {
  return provs
}

const getMunicipios = () => {
  return munips
}

const getMunicipiosByProvincia = (provincia) => {
  return munips.filter(muni => muni.provindex === provincia) 
}

export {print, getProvincias, getMunicipios, getMunicipiosByProvincia}