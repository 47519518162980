import { API_URL } from '../config.js'

const request_get = async (requestPath) => {
    const token =sessionStorage.getItem('token')
    return fetch(`${API_URL}${requestPath}`, {
        method: "GET",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (response.status === 200) { return response.json() }

            if (response.status === 404) {
                console.error(`Error elemento no encontrado status: ${response.status}`)
                // alert(`Error elemento no encontrado status: ${response.status}`)
                // console.error(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
                // alert(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
            } else if (response.status === 401) {
                console.error(`Error en la autentificacion con el servidor status ${response.status}`)
                alert(`Error en la autentificacion con el servidor status ${response.status}`)
            } else {
                console.error(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
                alert(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
            }
        })
        .then((responseData) => {
            if (responseData !== undefined) return responseData
        })
        .catch((error) => {
            console.error("Error al hacer la peticion al servidor " + API_URL + requestPath)
            console.error("Error:", error);
            alert("Error al hacer la peticion al servidor " + API_URL + requestPath);
        });
};
const request_post = async (requestPath, data) => {
    const token =sessionStorage.getItem('token')
    return fetch(`${API_URL}${requestPath}`, {
        method: "POST",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.status === 200) { return response.json() }

            if (response.status === 404) {
                console.error(`Error elemento no encontrado status: ${response.status}`)
                // alert(`Error elemento no encontrado status: ${response.status}`)
                // console.error(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
                // alert(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
            } else if (response.status === 401) {
                console.error(`Error en la autentificacion con el servidor status ${response.status}`)
                alert(`Error en la autentificacion con el servidor status ${response.status}`)
            } else {
                console.error(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
                alert(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
            }
        })
        .then((responseData) => {
            if (responseData !== undefined) return responseData
        })
        .catch((error) => {
            console.error("Error al hacer la peticion al servidor " + API_URL + requestPath)
            console.error("Error:", error);
            alert("Error al hacer la peticion al servidor " + API_URL + requestPath);
        });
};
const request_delete = async (requestPath, data) => {
    const token =sessionStorage.getItem('token') 
    return fetch(`${API_URL}${requestPath}/${data}`, {
        method: "DELETE",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        }
    })
        .then((response) => {
            if (response.status === 200) { return response.json() }

            if (response.status === 404) {
                console.error(`Error elemento no encontrado status: ${response.status}`)
                // alert(`Error elemento no encontrado status: ${response.status}`)
                // console.error(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
                // alert(`Error no existe el path ${API_URL}${requestPath} status: ${response.status}`)
            } else if (response.status === 401) {
                console.error(`Error en la autentificacion con el servidor status ${response.status}`)
                alert(`Error en la autentificacion con el servidor status ${response.status}`)
            } else {
                console.error(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
                alert(`Error al hacer la peticion al servidor ${API_URL}${requestPath} status: ${response.status}`)
            }
        })
        .then((responseData) => {
            if (responseData !== undefined) return responseData
        })
        .catch((error) => {
            console.error("Error al hacer la peticion al servidor " + API_URL + requestPath)
            console.error("Error:", error);
            alert("Error al hacer la peticion al servidor " + API_URL + requestPath);
        });
};

class User {
    static async list() {
        const response = await request_get("/v1/user/list")
        if (!response) alert("Error al obtener los usuarios")
        return response?.users
    }
    static async get() {
        const response = await request_get(`/v1/user/get/0`)
        if (!response) alert("Error al obtener el usuario")
        return response?.user
    }
    static async create(data) {
        const response = await request_post("/v1/user/create", data)
        if (response) {
            alert("Usuario creado correctamente");
        } else {
            alert("Error al crear el usuario");
        }
    }
    static async read(email) {
        const response = await request_get(`/v1/user/read/${email}`)
        if (!response) alert("Error al obtener el usuario")
        return response?.user
    }
    static async update(data) {
        const response = await request_post("/v1/user/update", data)
        if (!response) alert("Error al actualizar el usuario")
        return response?.user
    }
    static async delete(email) {
        const response = await request_delete("/v1/user/delete", email)
        if (response) { alert("Usuario eliminado correctamente") } else { alert("Error al eliminar el usuario") }
        return response
    }

}

class Dossier {
    static async structure(dossierCode) {
        const response = await request_get(`/v1/dossier/structure/${dossierCode}?format=flat`)
        if (!response) alert("No se encontro el dossier")
        return response
    }
    static async render(dossierCode) {
        const response = await request_get(`/v1/dossier/render/${dossierCode}`)
        if (!response) alert("No se encontro el dossier")
        return response
    }

}
class Flow {
    static async reset() {
        const response = await request_get("/v1/flow/reset")
        if (!response) alert("Error al resetear el flow")
        return response
    }
}
class Check {
    static async reset() {
        const response = await request_get("/v1/check/reset")
        if (!response) alert("Error al resetear el flow")
        return response
    }
}
class Cartridge {
    static async public() {
        const response = await request_get("/v1/cartridge/public")
        if (!response) alert("Error al obtener los cartridges")
        return response?.cartridges
    }
    static async list() {
        const response = await request_get("/v1/cartridge/list")
        if (!response) alert("Error al obtener los cartridges")
        return response?.cartridges
    }
    static async read(cartridgeCode) {
        const response = await request_get(`/v1/cartridge/read/${cartridgeCode}`)
        if (!response) alert("Error al obtener el cartridge")
        return response?.cartridge
    }
}
class Setup {
    static async public() {
        const response = await request_get("/v1/setup/public")
        if (!response) alert("Error al obtener el setup")
        return response
    }
}
export { User, Dossier, Flow, Check, Cartridge, Setup, request_get, request_post, request_delete }