// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-button {
  background-color: rgba(62, 59, 68, 0.7);
  border-width: 0px;
  border-radius: 4px;
  border-style: solid;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  min-height: 44px;
  min-width: 144px;
  max-width: 100%;
  max-height: 100%;
  font-family: Ping LCG Thin;
}

.my-button:hover {
  background-color: rgba(15, 15, 15, 0.98);
  border-color: var(--detail-color);
  border-width: 2px;
  border-style:solid;
}

.my-button:disabled { 
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,wCAAwC;EACxC,iCAAiC;EACjC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".my-button {\r\n  background-color: rgba(62, 59, 68, 0.7);\r\n  border-width: 0px;\r\n  border-radius: 4px;\r\n  border-style: solid;\r\n  color: white;\r\n  font-size: 1rem;\r\n  font-weight: bold;\r\n  cursor: pointer;\r\n  min-height: 44px;\r\n  min-width: 144px;\r\n  max-width: 100%;\r\n  max-height: 100%;\r\n  font-family: Ping LCG Thin;\r\n}\r\n\r\n.my-button:hover {\r\n  background-color: rgba(15, 15, 15, 0.98);\r\n  border-color: var(--detail-color);\r\n  border-width: 2px;\r\n  border-style:solid;\r\n}\r\n\r\n.my-button:disabled { \r\n  background-color: rgba(0, 0, 0, 0.05);\r\n  cursor: not-allowed;\r\n  pointer-events: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
