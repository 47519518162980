import React, { useEffect, useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import "./Relation.css";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "../../common/Text Area/TextArea";
import Input from "../../common/Input/Input";
import { API_URL, API_TOKEN } from "src/config.js";
import { useParams } from "react-router";
const Relation = ({ relation, onDelete, onChange }) => {
  const {expId, minifichaId} = useParams()
    
  const [selectedType, setSelectedType] = useState()
  
  const buttonStyle = {
    minWidth: "100px",
    alignSelf:'flex-end',
  };

  const handleDelete = () => {
    onDelete(relation);
  };

  const handleChange = name => value => {
    switch(name) {
      case 'name': {
        if (!value) {
          relation.persona = "";
          return;
        }
        const updatedRelation = { ...relation };
        updatedRelation.person = value;
        onChange(updatedRelation);
        break;
      }
      case 'type': {
        if (!value) {
          relation.type = "";
          relation.relation = ''
          return;
        }
        const updatedRelation = { ...relation };
        updatedRelation.relation = value.value;
        updatedRelation.type = value.data
        onChange(updatedRelation);
        break;
      }
    }
  };

  const onChangeThis = (e) => {
    if (e.target.name === "heredero") {
      const updatedRelation = {...relation}
      updatedRelation[e.target.name] = e.target.checked
      onChange(updatedRelation)
      return;
    }
    const updatedRelation = {...relation}
    updatedRelation[e.target.name] = e.target.value
    onChange(updatedRelation)
  }

  const [personaOptions, setPersonaOptions] = useState()

  useEffect(() => {
    fetch(`${API_URL}/v1/dossier/list/header.code:asc/650c69f489e41389f88a4c87/0/1000000`, {
      method: "GET",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const filtered = data.filter((dossier) => dossier.dossier.file.expId === expId && dossier.dossier.header.code !== minifichaId)
        setPersonaOptions(filtered.map(filteredData => {return {label:filteredData.dossier.header.name, value: filteredData.dossier.header.code}}))
      }).catch(error => {
        console.log(error)
      })
  }, [expId, minifichaId])

  const tipoOptions = [
    {label:'Cohabitante', value: 'cohabitante', data: 'general'},
    {label:'Vecino/a', value: 'vecino', data: 'general'},
    {label:'Conocido/a', value: 'conocido', data: 'general'},
    {label:'Padre/Madre', value: 'padre', data: 'familiar'},
    {label:'Hijo/a', value: 'hijo', data: 'familiar'},
    {label:'Hermano/a', value: 'hermano', data: 'familiar'},
    {label:'Tío/a', value: 'tio', data: 'familiar'},
    {label:'Sobrino/a', value: 'sobrino', data: 'familiar'},
    {label:'Primo/a', value: 'primo', data: 'familiar'},
    {label:'Cónyuge', value: 'conyuge', data: 'familiar'},
  ]
  
  useEffect(() => {
    if(!personaOptions){
      return;
    }
    const mySelectedType = tipoOptions.find(tipo => tipo.value === relation.relation)
    setSelectedType(mySelectedType)
  }, [personaOptions])

  return (
    <div className="action-container">
      <div className="action-container-line">
        <div className="flex-input-relation">
          <Select
            isSearchable={true}
            isClearable={true}
            options={personaOptions}
            placeholder="Persona"
            onChange={handleChange("name")}
            label="Persona"
            value={relation.person}
            activeLabel={true}
            style={{ width: "200px" }}
          />
        </div>
        <div className="flex-input-relation">
          <Select
            isSearchable={true}
            isClearable={true}
            options={tipoOptions}
            placeholder="Tipo"
            onChange={handleChange("type")}
            label="Tipo"
            activeLabel={true}
            value={selectedType}
            style={{ width: "200px" }}
          />
        </div>
        <div className="flex-input-relation">
          <Input
            type="checkbox"
            name="heredero"
            onChange={onChangeThis}
            checked={relation.heredero}
            label="Heredero"
            activeLabel={true}
          />
        </div>
      </div>
      <div className="action-container-line">
        <div className="flex-input-relation">
          <TextArea
            id="notas"
            value={relation.notas}
            name="notas"
            placeholder="Notas"
            onChange={onChangeThis}
            label="Notas"
            activeLabel={true}
          />
        </div>
      </div>
      <Button
        onClick={handleDelete}
        content="Eliminar"
        buttonStyle={buttonStyle}
      />
    </div>
  );
};

export default Relation;
