import React, {  useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import { MINIFICHASCOLUMNS } from 'src/columns'
import SortingTable from 'src/modules/components/porOrganizar/Table/SortingTable'
import { API_URL, API_TOKEN } from "src/config.js";
import Button from 'src/modules/components/common/Button/Button';
const Fichas = () => {
  let { expId } = useParams();
  const [minifichaId, setMinifichaId] = useState()
  const navigate = useNavigate()

  function generarCodigo(arrayDeObjetos) {
    const length = arrayDeObjetos.length + 1;
    const minCifras = Math.max(4, String(length).length);
    const numeroFormateado = String(length).padStart(minCifras, "0");
    setMinifichaId(`G-${numeroFormateado}`);
  }
  useEffect(() => {
    fetch(`${API_URL}/v1/dossier/list/header.code:asc/651ebe7a83b1bf68ed28b6d5/0/1000000`, {
      method: "GET",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        generarCodigo(data)
        const filtered = data.filter((dossier) => dossier.dossier.file.expId === expId)
        console.log(filtered)
        const dossiers = filtered.map(dossier => {
          const edit = <a href={`/editficha/${dossier.dossier.file.expId}/${dossier.dossier.header.code}`}>Editar</a>
          return {edit, name:dossier.dossier.header.name, code: dossier.dossier.header.code}
        })
        setDossiers(dossiers);
      }).catch(error => {
        console.log(error)
      });
    }, [])
    const [dossiers, setDossiers] = useState()
    const navigateToEdit = () => {
      navigate(`/editficha/${expId}/${minifichaId}`)
    }
    
    const divStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }

    return (
      <div style={divStyle}>
        { dossiers?.length > 0 ? <SortingTable columnsData={MINIFICHASCOLUMNS} tbodyData={dossiers}/> : <h1 style={{display:'flex', textAlign: 'center', flexDirection: 'column'}}>No hay Minifichas para este expediente</h1>}
        <Button
          content='+'
          onClick={navigateToEdit}
        />
      </div>
  )
}

export default Fichas