import { useState } from "react";

export function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? item : initialValue;
    } catch (e) {
      console.log(e);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueString = value;
      setStoredValue(valueString);
      window.sessionStorage.setItem(key, valueString);
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
}
