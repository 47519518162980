// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-175oi2r {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  row-gap: 0.5rem;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  flex: 1 1;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  flex-basis: auto;
  z-index: 0;
  min-height: calc(100vh - 76px);
  overflow: overlay;
  background: var(--primary-color);
}

.resize_fit_center {
  width:auto;
  max-height: 100%;
  vertical-align: middle;
  object-fit: contain;
}

.server-error{
  display: flex;
  justify-content: center;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,SAAY;EACZ,QAAQ;EACR,WAAW;EACX,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,UAAU;EACV,8BAA8B;EAC9B,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".css-175oi2r {\r\n  align-items: stretch;\r\n  box-sizing: border-box;\r\n  display: flex;\r\n  flex-basis: auto;\r\n  flex-direction: column;\r\n  flex-shrink: 0;\r\n  list-style: none;\r\n  margin: 0px;\r\n  padding: 0px;\r\n  position: relative;\r\n  text-decoration: none;\r\n  z-index: 0;\r\n  row-gap: 0.5rem;\r\n}\r\n\r\n.page-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  row-gap: 2rem;\r\n  flex: 1 1 0%;\r\n  top: 0px;\r\n  bottom: 0px;\r\n  right: 0px;\r\n  left: 0px;\r\n  flex-basis: auto;\r\n  z-index: 0;\r\n  min-height: calc(100vh - 76px);\r\n  overflow: overlay;\r\n  background: var(--primary-color);\r\n}\r\n\r\n.resize_fit_center {\r\n  width:auto;\r\n  max-height: 100%;\r\n  vertical-align: middle;\r\n  object-fit: contain;\r\n}\r\n\r\n.server-error{\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
