// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-container {
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 8px;
  min-width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.action-container-line {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-direction: row;
  border-radius: 8px;
  min-width: -webkit-fill-available;
  justify-content: space-between;
}

.action-container-line label {
  flex: 1 1 30%;
}

.action-container p {
  max-width: 262px;
  overflow: hidden;
}

.flex-input-relation {
  flex: 1 1 256px
}`, "",{"version":3,"sources":["webpack://./src/modules/components/Editar/Relation/Relation.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,iCAAiC;EACjC,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,iCAAiC;EACjC,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;AACF","sourcesContent":[".action-container {\r\n  background: rgba(0, 0, 0, 0.3);\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  min-width: -webkit-fill-available;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap:10px;\r\n}\r\n\r\n.action-container-line {\r\n  display: flex;\r\n  align-items: center;\r\n  column-gap: 10px;\r\n  flex-direction: row;\r\n  border-radius: 8px;\r\n  min-width: -webkit-fill-available;\r\n  justify-content: space-between;\r\n}\r\n\r\n.action-container-line label {\r\n  flex: 1 1 30%;\r\n}\r\n\r\n.action-container p {\r\n  max-width: 262px;\r\n  overflow: hidden;\r\n}\r\n\r\n.flex-input-relation {\r\n  flex: 1 1 256px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
