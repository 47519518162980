import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
const NavBarOption = ({ val, setTitle }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const handleTitleValue = () => {
    setTitle(val.title);
  };
  return (
    <div
      onMouseEnter={() => {
        if (val.submenus) {
          setShowSubmenu(true);
        }
      }}
      onMouseLeave={() => setShowSubmenu(false)}
      className="submenu-container"
    >
      <NavLink
        id={window.location.pathname === val.path ? "active" : ""}
        to={val.path}
        onClick={() => {
          handleTitleValue();
          if (val.onClick) {
            val.onClick();
          }
        }}
      >
        <div id="title">{val.title}</div>
      </NavLink>
      {showSubmenu && (
        <li className="submenu">
          {val.submenus.map((submenu) => {
            return (
              <ul key={submenu.id}>
                <NavLink
                  id={window.location.pathname === submenu.path ? "active" : ""}
                  to={submenu.path}
                  onClick={() => {
                    handleTitleValue();
                    if (submenu.onClick) {
                      submenu.onClick();
                    }
                  }}
                >
                  {submenu.title}
                </NavLink>
              </ul>
            );
          })}
        </li>
      )}
    </div>
  );
};

export default NavBarOption;
