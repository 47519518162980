import React from "react";
import "./Title.css";
const Title = (props) => {
  const color = props.color || "white";
  const fontSize = props.fontSize || "28px";
  return (
    <div className="title-container">
      <h1
        className="title"
        style={{ color: { color }, fontSize: { fontSize } }}
      >
        {props.text}
      </h1>
    </div>
  );
};

export default Title;
