import React, { useEffect, useState, useRef } from "react";
import "./Editar.css";
import Input from "src/modules/components/common/Input/Input";
import Title from "src/modules/components/common/Title/Title";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Button from "src/modules/components/common/Button/Button";
import Relation from "src/modules/components/Editar/Relation/Relation";
import { API_URL, API_TOKEN } from "src/config.js";
import Select from "src/modules/components/common/Select/Select";
import Direction from "src/modules/components/Editar/Direction/Direction";
import { useParams } from "react-router-dom";
import {getProvincias} from 'src/services/locations'
const Editar = (props) => {
  const { expId, minifichaId } = useParams();
  const buttonStyle = props.buttonStyle || {
    minHeight: "44px",
    minWidth: "144px",
  };

  const defaultFicha = {
    nombre: "",
    apellido1: "",
    apellido2: "",
    notas: "",
    fechaNacimiento: "",
    fechaDefuncion: "",
    expId,
    cutm: "",
    direcciones: [],
    relaciones: [],
    pais: "España",
    telefonos: [],
    emails: [],
    otros: [],
  };

  const [minificha, setMinificha] = useState(defaultFicha);
  const [provincias, setProvincias] = useState([]);
  
  useEffect(() => {
    setProvincias(
      getProvincias().map((provincia) => {
        return { label: provincia.ux, value: provincia.ux };
      })
    );
  }, [])

  const [relaciones, setRelaciones] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [telefonos, setTelefonos] = useState([]);
  const [emails, setEmails] = useState([]);
  const [otros, setOtros] = useState([]);

  const save = () => {
    const dossier = {
      header: {
        name: `${minificha.apellido1} ${minificha.apellido2}, ${minificha.nombre}`,
        code: minifichaId,
        clients: [],
        owner: "",
        status: "open",
        filetype: "minificha",
      },
      file: minificha,
    };
    // Realizar la petición POST a la API
    fetch(`${API_URL}/v1/dossier/crupdate`, {
      method: "POST",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dossier),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Actualizar la segunda columna con los datos recibidos
        if (responseData.error) {
          alert(
            `Error al crear la minificha error: ${responseData.error} responseData: ${responseData.message}`
          );
        } else {
          alert("Minificha creado correctamente");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error al crear el dossier: ", dossier);
      });
  };

  const deleteMinificha = (id) => {
    // Realizar la petición POST a la API
    fetch(`${API_URL}/v1/dossier/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.error) {
          alert(
            `Error al elimnar la minificha error: ${responseData.error} responseData: ${responseData.message}`
          );
        } else {
          alert("Minificha eliminado correctamente");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error al eliminar la minificha");
      });
  };

  const confirmDelete = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar la minificha: ${minificha.apellido1} ${minificha.apellido2}, ${minificha.nombre}?`
    );

    if (confirmation) {
      deleteMinificha(minifichaId);
    }
  };

  const addButton = (e) => {
    const name = e.target.getAttribute("name").split("-")[1];
    switch (name) {
      case "direction": {
        const newDirection = {
          provincia: "",
          municipio: "",
          pais: "España",
          fechas: []
        };
        setDirecciones((prevValue) => prevValue.concat(newDirection));
        break;
      }
      case "relation": {
        const newRelation = {
          type: "",
          person: null,
        };
        setRelaciones((prevValue) => prevValue.concat(newRelation));
        break;
      }
      case "telefono": {
        const newTelefono = "";
        setTelefonos((prevValues) => prevValues.concat(newTelefono));
        break;
      }
      case "email": {
        const newEmail = "";
        setEmails((prevValues) => prevValues.concat(newEmail));
        break;
      }
      case "other": {
        const newOtro = "";
        setOtros((prevValues) => prevValues.concat(newOtro));
        break;
      }
    }
  };

  const handleDeleteRelation = (relationToDelete) => {
    const updatedRelations = relaciones.filter(
      (relation) => relation !== relationToDelete
    );
    setRelaciones(updatedRelations);
  };

  const handleDeleteDirection = (accionToDelete) => {
    const updatedDirections = direcciones.filter(
      (direction) => direction !== accionToDelete
    );
    setDirecciones(updatedDirections);
  };

  const handleDeleteTelefono = (telefonoToDelete) => {
    const updatedTelefonos = telefonos.filter(
      (telefono) => telefono !== telefonoToDelete
    );
    setTelefonos(updatedTelefonos);
  };

  const handleDeleteEmail = (emailToDelete) => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    setEmails(updatedEmails);
  };

  const handleDeleteOtro = (otroToDelete) => {
    const updatedOtros = otros.filter((otros) => otros !== otroToDelete);
    setOtros(updatedOtros);
  };

  const readDossier = async () => {
    fetch(`${API_URL}/v1/dossier/read/${minifichaId}`, {
      method: "GET",
      headers: {
        Authorization: API_TOKEN,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.dossier) {
          return;
        }
        setMinificha(responseData.dossier.file);
        setRelaciones(responseData.dossier.file.relaciones);
        setDirecciones(responseData.dossier.file.direcciones);
        setTelefonos(responseData.dossier.file.telefonos)
        setEmails(responseData.dossier.file.emails)
        setOtros(responseData.dossier.file.otros)
      })
      .catch((error) => {
        console.log("Nueva minificha", error);
      });
  };

  useEffect(() => {
    readDossier();
  }, []);

  useEffect(() => {
    setMinificha({ ...minificha, relaciones });
  }, [relaciones]);

  useEffect(() => {
    setMinificha({ ...minificha, direcciones });
  }, [direcciones]);

  const onChange = (e) => {
    setMinificha({ ...minificha, [e.target.name]: e.target.value });
  };

  const onChangeContact = (e) => {
    const [name, index] = e.target.name.split("-");
    if (name.includes("telefono")) {
      telefonos[Number(index)] = e.target.value;
      setMinificha({ ...minificha, telefonos });
      return;
    } else if (name.includes("email")) {
      emails[Number(index)] = e.target.value;
      setMinificha({ ...minificha, emails });
      return;
    } else if (name.includes("otro")) {
      otros[Number(index)] = e.target.value;
      setMinificha({ ...minificha, otros });
      return;
    }
  };
  const emptyMiniFicha = () => {
    setMinificha(defaultFicha);
    setRelaciones([]);
  };

  const onSelectProvNacimiento = (value) => {
    if(!value) {
      setMinificha((prevValue) => {
        return { ...prevValue, provNacimiento: {} };
      });
      return
    }
    setMinificha((prevValue) => {
      return { ...prevValue, provNacimiento: value.value };
    });
  };

  const onSelectProvDefuncion = (value) => {
    if(!value) {
      setMinificha((prevValue) => {
        return { ...prevValue, provDefuncion: {} };
      });
      return
    }
    setMinificha((prevValue) => {
      return { ...prevValue, provDefuncion: value.value };
    });
  };

  const [selectedProvNaci, setSelectedProvNaci] = useState();
  const [selectedProvDefu, setSelectedProvDefu] = useState();

  useEffect(() => {
    if (!provincias) {
      return;
    }
    const provNaci = provincias.find(
      (provincia) => provincia.value === minificha.provNacimiento
    );
    const provDefu = provincias.find(
      (provincia) => provincia.value === minificha.provDefuncion
    );
    setSelectedProvNaci(provNaci);
    setSelectedProvDefu(provDefu);
  }, [minificha]);

  return (
    <>
      <div className="checks-container">
        <div className="group-container">
          <div
            style={{
              rowGap: "10px",
              display: "flex",
              flexDirection: "column",
            }}>
            <Title text="Minificha" />
            <Input
              name="nombre"
              type="text"
              placeholder="Nombre"
              onChange={onChange}
              value={minificha.nombre}
              label="Nombre"
              activeLabel={true}
            />
            <Input
              name="apellido1"
              type="text"
              placeholder="Primer Apellido"
              onChange={onChange}
              value={minificha.apellido1}
              label="Primer Apellido"
              activeLabel={true}
            />
            <Input
              name="apellido2"
              type="text"
              placeholder="Segundo Apellido"
              onChange={onChange}
              value={minificha.apellido2}
              label="Segundo Apellido"
              activeLabel={true}
            />
            <Input
              name="fechaNacimiento"
              type="date"
              placeholder="Fecha de Nacimiento"
              onChange={onChange}
              value={minificha.fechaNacimiento}
              label="Fecha de Nacimiento"
              activeLabel={true}
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="provNaci"
              options={provincias}
              placeholder="Selecciona Lugar de Nacimiento..."
              onChange={onSelectProvNacimiento}
              label="Selecciona Lugar de Nacimiento"
              activeLabel={true}
              value={selectedProvNaci}
            />
            <Input
              name="fechaDefuncion"
              type="date"
              placeholder="Fecha de Defunción"
              value={minificha.fechaDefuncion}
              onChange={onChange}
              label="Fecha de Defunción"
              activeLabel={true}
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="provDef"
              options={provincias}
              placeholder="Selecciona Lugar de Fallecimiento..."
              onChange={onSelectProvDefuncion}
              label="Selecciona Lugar de Fallecimiento"
              activeLabel={true}
              value={selectedProvDefu}
            />
            <TextArea
              id="notas"
              value={minificha.notas}
              name="notas"
              placeholder="Notas"
              onChange={onChange}
              label="Notas"
              activeLabel={true}
            />

            <div className="actions-buttons">
              <Button
                id="createCheck"
                onClick={save}
                content="Guardar"
              />
              <Button
                id="deleteCheck"
                onClick={confirmDelete}
                content="Borrar"
              />
            </div>
          </div>
        </div>
        <div className="group-container">
          <div className="actions-buttons-container">
            <div className="actions-container">
              <span className="actions-text">
                <Title text="Relaciones:" />
                <Button
                  name="add-relation"
                  onClick={addButton}
                  content="+"
                  buttonStyle={buttonStyle}
                />
              </span>
              {relaciones.map((relation, index) => (
                <Relation
                  key={index}
                  relation={relation}
                  onDelete={handleDeleteRelation}
                  onChange={(updatedRelation) => {
                    const updatedMinificha = { ...minificha };
                    updatedMinificha.relaciones[index] = updatedRelation;
                    setMinificha(updatedMinificha);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="checks-container">
        <div className="group-container">
          <span className="actions-text">
            <Title text="Direcciones:" />
            <Button
              name="add-direction"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
          </span>
          {direcciones.map((direction, index) => (
            <Direction
              key={index}
              direction={direction}
              onDelete={handleDeleteDirection}
              onChange={(updatedDirection) => {
                const updatedMinificha = { ...minificha };
                updatedMinificha.direcciones[index] = updatedDirection;
                setMinificha(updatedMinificha);
              }}
            />
          ))}
        </div>
        <div className="group-container">
            <Title text="Contacto:" />
          <div>
            <h2>Teléfonos</h2>
            <div className="contacto-container">
              {telefonos.map((telefono, index) => (
                <div className="contacto-line">
                  <Input
                    name={`telefono-${index}`}
                    type="text"
                    placeholder="Telefono"
                    onChange={onChangeContact}
                    value={telefono}
                    key={index}
                  />
                  <Button
                    onClick={() => handleDeleteTelefono(telefono)}
                    content="Eliminar"
                    buttonStyle={buttonStyle}
                  />
                </div>
              ))}
            </div>
            <Button
              name="add-telefono"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
          </div>
          <div>
            <h2>Emails</h2>
            <div className="contacto-container">
              {emails.map((email, index) => (
                <div className="contacto-line">
                  <Input
                    name={`email-${index}`}
                    type="text"
                    placeholder="Email"
                    onChange={onChangeContact}
                    value={email}
                    key={index}
                  />
                  <Button
                    onClick={() => handleDeleteEmail(email)}
                    content="Eliminar"
                    buttonStyle={buttonStyle}
                    id={index}
                  />
                </div>
              ))}
            </div>
            <Button
              name="add-email"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
          </div>
          <div>
            <h2>Otros</h2>
            <div className="contacto-container">
              {otros.map((otro, index) => (
                <div className="contacto-line">
                  <Input
                    name={`otro-${index}`}
                    type="text"
                    placeholder="Otro"
                    onChange={onChangeContact}
                    value={otro}
                    key={index}
                  />
                  <Button
                    onClick={() => handleDeleteOtro(otro)}
                    content="Eliminar"
                    buttonStyle={buttonStyle}
                  />
                </div>
              ))}
            </div>
            <Button
              name="add-other"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Editar;
