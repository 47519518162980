import React, { useEffect, useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import "./Direction.css";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "../../common/Text Area/TextArea";
import Input from "../../common/Input/Input";
import {
  getProvincias,
  getMunicipiosByProvincia,
} from "src/services/locations";
const Direction = ({ direction, onDelete, onChange, expId }) => {
  const handleDelete = () => {
    onDelete(direction);
  };

  const [provincias, setProvincias] = useState([]);

  useEffect(() => {
    setProvincias(
      getProvincias().map((provincia) => {
        return { label: provincia.ux, value: provincia.index };
      })
    );
    if(direction.provincia) {
      getMunicipios(direction.provincia.value)
    }
  }, []);

  const [municipios, setMunicipios] = useState();
  const buttonStyle = {
    minWidth: "100px",
    alignSelf: "flex-end",
  };

  const getMunicipios = (provincia) => {
    const data = getMunicipiosByProvincia(provincia);
    setMunicipios(
      data.map((municipio) => {
        return { label: municipio.ux, value: municipio.index };
      })
    );
  };

  const handleChange = (name) => (value) => {
    switch (name) {
      case "provincia": {
        if (!value) {
          const updatedDirection = {
            ...direction,
            municipio: null,
            provincia: null,
            fechas: [],
          };
          onChange(updatedDirection);
          setMunicipios([]);
          return;
        }
        const updatedDirection = { ...direction };
        updatedDirection.provincia = value;
        onChange(updatedDirection);
        getMunicipios(value.value);
        break;
      }
      case "municipio": {
        if (!value) {
          const updatedDirection = {
            ...direction,
            municipio: null,
          };
          onChange(updatedDirection);
          return;
        }
        const updatedDirection = { ...direction };
        updatedDirection.municipio = value;
        onChange(updatedDirection);
        break;
      }
    }
  };

  const onChangeData = (e) => {
    const updatedDirection = { ...direction, [e.target.name]: e.target.value };
    onChange(updatedDirection);
  };

  const onChangeFecha = (e) => {
    const [name, index] = e.target.name.split("-");
    direction.fechas[index] = e.target.value;
    onChange(direction);
  };

  const handleDeleteFechas = (fechaToDelete) => {
    const updatedFechas = direction.fechas.filter(
      (fecha) => fecha !== fechaToDelete
    );
    const updatedDirection = { ...direction, fechas: updatedFechas };
    onChange(updatedDirection);
  };

  const addButton = (e) => {
    const name = e.target.getAttribute("name").split("-")[1];
    switch (name) {
      case "fecha": {
        const fecha = "";
        if (!direction.fechas) direction.fechas = [];
        const updatedFechas = direction.fechas.concat(fecha);
        const updatedDirection = { ...direction, fechas: updatedFechas };
        onChange(updatedDirection);
        break;
      }
    }
  };

  return (
    <div className="action-container">
      <div className="action-container-line">
        <div className="flex-input-relation">
          <Select
            isSearchable={true}
            isClearable={true}
            name="provincia"
            options={provincias}
            placeholder="Provincia"
            onChange={handleChange("provincia")}
            label="Provincia"
            activeLabel={true}
            style={{ width: "200px" }}
            value={direction.provincia}
          />
        </div>
        <div className="flex-input-relation">
          <Select
            isDisabled={
              direction.provincia === null || direction.provincia === ""
            }
            isSearchable={true}
            isClearable={true}
            name="municipio"
            options={municipios}
            placeholder="Tipo"
            onChange={handleChange("municipio")}
            label="Municipio"
            activeLabel={true}
            style={{ width: "200px" }}
            value={direction.municipio}
          />
        </div>
      </div>
      <div className="action-container-line">
        <div className="flex-input-relation">
          <Input
            label="Dirección"
            activeLabel={true}
            name="direccion"
            type="text"
            placeholder="Dirección"
            value={direction.direccion}
            onChange={onChangeData}
          />
        </div>
        <div className="flex-input-relation">
          <TextArea
            id="notas"
            value={direction.notas}
            name="notas"
            placeholder="Notas"
            onChange={onChangeData}
            label="Notas"
            activeLabel={true}
          />
        </div>
      </div>
      <div className="action-container-line">
          <h2>Fechas</h2>
            <Button
              name="add-fecha"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
        </div>
        <div className="contacto-container">
          {direction.fechas?.map((fecha, index) => (
            <div className="contacto-line">
              <Input
                name={`fecha-${index}`}
                type="date"
                placeholder="Fecha"
                onChange={onChangeFecha}
                value={fecha}
                key={index}
              />
              <Button
                onClick={() => handleDeleteFechas(fecha)}
                content="Eliminar"
                buttonStyle={buttonStyle}
              />
            </div>
          ))}
      </div>
      <Button
        onClick={handleDelete}
        content="Eliminar"
        buttonStyle={buttonStyle}
      />
    </div>
  );
};

export default Direction;
