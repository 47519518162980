// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area-style {
  --placeholderTextColor: rgba(255, 255, 255, 1);
  align-self: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.4);
  border-style: solid;
  color: rgb(255, 255, 255);
  height: 40px;
  padding-left: 12px;
  width: calc(100% - 12px);
  font-family: 'Ping LCG Regular';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0px;
  flex-basis: 328px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: -moz-fit-content;
  min-width: fit-content;
  resize: vertical;
}

@media (max-width: 750px) {
  .text-area-style {
    flex-basis: auto;
    align-self: self-start;
    margin-bottom: 5px;
  }
}
  `, "",{"version":3,"sources":["webpack://./src/modules/components/common/Text Area/TextArea.css"],"names":[],"mappings":"AAAA;EACE,8CAA8C;EAC9C,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;EAClB,mBAAmB;EACnB,sCAAsC;EACtC,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,2BAA2B;EAC3B,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":[".text-area-style {\r\n  --placeholderTextColor: rgba(255, 255, 255, 1);\r\n  align-self: center;\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  border-radius: 4px;\r\n  border-width: 0.5px;\r\n  border-color: rgba(255, 255, 255, 0.4);\r\n  border-style: solid;\r\n  color: rgb(255, 255, 255);\r\n  height: 40px;\r\n  padding-left: 12px;\r\n  width: calc(100% - 12px);\r\n  font-family: 'Ping LCG Regular';\r\n  font-weight: 400;\r\n  font-style: normal;\r\n  font-size: 16px;\r\n  letter-spacing: 0px;\r\n  flex-basis: 328px;\r\n  flex-grow: 1;\r\n  flex-shrink: 1;\r\n  min-width: -moz-fit-content;\r\n  min-width: fit-content;\r\n  resize: vertical;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .text-area-style {\r\n    flex-basis: auto;\r\n    align-self: self-start;\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
