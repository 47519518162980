import React, { useEffect, useState } from "react";
import "./Input.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
const Input = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [icon, setIcon] = useState(eye);
  const handleToggle = () => {
    if (passwordShown) {
      setIcon(eye);
      setPasswordShown(false);
    } else {
      setIcon(eyeOff);
      setPasswordShown(true);
    }
  };
  return (
    <div className='input-container'>
      {
        props.activeLabel === true && (<label className="input-label">{props.label || 'texto'}</label>)
      }
      {props.type === "checkbox" ? 
         <label className="custom-checkbox">
          <input 
            name={props.name}
            type="checkbox" 
            checked={props.checked} 
            onChange={props.onChange}
          />
         </label>
        :(
        <input
          name={props.name}
          type={props.type === "password" ? (passwordShown ? 'text' : 'password') : (props.type || "text")}
          placeholder={props.placeholder || "text"}
          onChange={props.onChange}
          className={`input-${props.type}`}
          value={props.value}
          style={props.style}
          checked={props.checked}
        />
        )
      }
      {props.type === "password" && (
          <Icon
            onClick={handleToggle}
            className='eye'
            icon={icon}
            size={25}
          />
      )}
    </div>
  )
};

export default Input;
