import React from "react";
import './TextArea.css'
const TextArea = (props) => {
  return (
    <div className="input-container">
      {
        props.activeLabel === true && (<label className="input-label">{props.label || 'texto'}</label>)
      }
      <textarea
        name={props.name}
        type={props.type || "text"}
        placeholder={props.placeholder || "text"}
        onChange={props.onChange}
        className="text-area-style"
        value={props.value}
        style={props.style}
      />
    </div>
  );
};

export default TextArea;
